import { styled } from "@mui/material/styles";

const SidebarContainer = styled("nav")({
  width: "240px",
  backgroundColor: "white",
  padding: "20px",
  borderRight: "1px solid #e0e0e0",
  display: "flex",
  flexDirection: "column",
});

const Logo = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginBottom: "30px",
});

const LogoCircle = styled("div")({
  width: "32px",
  height: "32px",
  backgroundColor: "#f6851b",
  borderRadius: "50%",
});

const NavItem = styled("a")<{ active?: boolean }>(({ active }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  padding: "12px",
  color: active ? "#037dd6" : "#6f6f6f",
  textDecoration: "none",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: active ? "#f0f8ff" : "transparent",
  "&:hover": {
    backgroundColor: active ? "#f0f8ff" : "#f5f5f5",
  },
}));

const Terms = styled("a")({
  marginTop: "auto",
  padding: "16px",
  color: "#6f6f6f",
  fontSize: "14px",
  textDecoration: "none",
});

/**
 * Sidebar component containing navigation and branding
 */
export function Sidebar() {
  return (
    <SidebarContainer>
      <Logo>
        <LogoCircle />
        <span>Portfolio</span>
      </Logo>

      <NavItem href="#">Overview</NavItem>
      <NavItem href="#">Move crypto</NavItem>
      <NavItem href="#">Tokens</NavItem>
      <NavItem href="#">NFTs</NavItem>
      <NavItem href="#">Dapps</NavItem>
      <NavItem href="#">Games</NavItem>
      <NavItem href="#" active>
        Taxes
      </NavItem>

      <Terms href="#">Terms of Use</Terms>
    </SidebarContainer>
  );
}
