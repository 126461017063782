import { styled } from "@mui/material/styles";
import { type ReactNode } from "react";

const MainContainer = styled("main")({
  flexGrow: 1,
});

const Header = styled("header")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 24px",
  backgroundColor: "white",
  borderBottom: "1px solid #e0e0e0",
});

const PageTitle = styled("h1")({
  fontSize: "24px",
  fontWeight: 600,
});

const SearchBar = styled("div")({
  flexGrow: 1,
  maxWidth: "600px",
  margin: "0 24px",
  "& input": {
    width: "100%",
    padding: "8px 16px",
    border: "1px solid #e0e0e0",
    borderRadius: "24px",
    backgroundColor: "#f5f5f5",
  },
});

const HeaderActions = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

interface MainContentProps {
  /** The content to be rendered in the main area */
  children: ReactNode;
}

/**
 * MainContent component containing the header and content area
 * @param props - Component props containing children to render in the main area
 * @returns React component with the main layout and content
 */
export function MainContent({ children }: MainContentProps) {
  return (
    <MainContainer>
      <Header>
        <PageTitle>Taxes</PageTitle>
        <SearchBar>
          <input type="text" placeholder="Search for a token..." />
        </SearchBar>
        <HeaderActions>
          <span>18</span>
          <span>🔔</span>
          <span>👤</span>
        </HeaderActions>
      </Header>

      {children}
    </MainContainer>
  );
}
