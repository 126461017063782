import { TaxCalculator } from "@ctc/react-sdk";
import { styled } from "@mui/material/styles";

import { MainContent } from "./components/MainContent";
import { Sidebar } from "./components/Sidebar";

const AppContainer = styled("div")({
  display: "flex",
  minHeight: "100vh",
  backgroundColor: "#f5f5f5",
});

/**
 * Main application component that renders the sidebar and main content
 */
export function App() {
  return (
    <AppContainer>
      <Sidebar />
      <MainContent>
        <TaxCalculator />
      </MainContent>
    </AppContainer>
  );
}
